'use strict';

import Flickity from 'flickity';

export default class Hero {
  constructor() {
    this.element = document.getElementById('hero');

    // Identify the info block, a container for SVG info labels
    let infoEls = this.element.querySelectorAll('.info');

    // Identify dot elements within each info block, add to array
    this.dots = [];
    for (let i = 0; i < infoEls.length; i++) {
      let dotEls = infoEls[i].querySelectorAll('.dot-outer');
      for (let j = 0; j < dotEls.length; j++) {
        this.dots.push(dotEls[j]);
      }
    }

    // Add event listeners to each of the dot elements after identifying them
    this.initInfoDots();
  }

  initFlickity(callback) {
    this.flickity = new Flickity( this.element, {
      // adaptiveHeight: true,
      autoPlay: 5000,
      dragThreshold: 20,
      friction: 0.15,
      prevNextButtons: false,
      selectedAttraction: 0.01
    });

    setTimeout(callback, 1000);
  }

  bindEvent(el, eventName, eventHandler) {
    if (el.addEventListener){
      el.addEventListener(eventName, eventHandler, false);
    }
    else if (el.attachEvent){
      el.attachEvent('on'+eventName, eventHandler);
    }
  }

  // Add event listeners to each of the dot elements
  initInfoDots() {
    for (let i = 0; i < this.dots.length; i++) {
      this.bindEvent(this.dots[i], 'mouseover', this.showInfo);
      this.bindEvent(this.dots[i], 'mouseout', this.showInfo);
      this.bindEvent(this.dots[i], 'focus', this.showInfo);
      this.bindEvent(this.dots[i], 'blur', this.showInfo);
    }
  }

  // Toggles class on parent node (group of each dot/line/text info sub block)
  showInfo(event) {
    let targetElement = event.target;
    let info = targetElement.parentNode;
    let show = event.type == 'mouseover' || event.type == 'focus';
    if (show) {
      info.classList.add('show');
    }
    else {
      info.classList.remove('show');
    }
  }
}
