'use strict';

export default class Sticky {
  constructor() {
    this.element = document.getElementById('sticky');
    if(document.getElementById('pre-order'))
      this.scrollto = document.getElementById('pre-order');
    else
      this.scrollto = document.getElementById('special-offer');
  }
}
