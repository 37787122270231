'use strict';

export default class Button {
  constructor() {
    let shareBtns = document.querySelectorAll('[data-share]');
    for (let i = 0; i < shareBtns.length; i++) {
      let btn = shareBtns[i];

      // set up the popup window params
      this.popup = {
        scrollbars: 'yes',
        resizable: 'yes',
        toolbar: 'no',
        location: 'yes',
        width: 550,
        height: 420,
        left: Math.round((screen.width / 2) - (550)),
        top: 0
      };
      if (screen.height > this.popup.height) {
        this.popup.top = Math.round((screen.height / 2) - (this.popup.height / 2));
      }

      let _this = this;
      this.bindEvent(btn, 'click', function(e) {
        e.preventDefault();
        _this.openShareWindow(e)
      })
    }

    // Track clicks on phone button
    let phonelinks = document.querySelectorAll('.phonelink');
    for (var i = 0; i < phonelinks.length; i++) {
      this.bindEvent(phonelinks[i], 'click', function(e) {
        if (typeof ga == 'function')
          ga('send', 'event', 'phonelink', 'clicked', e.srcElement.text);
        if (typeof fbq == 'function')
          fbq('trackCustom', 'clickPhone', {'name': e.srcElement.text});
      });
    }
  }

  openShareWindow(e) {
    let network = e.target.getAttribute('data-share');
    let shareUrl = false, vals;
    switch (network) {
    case 'facebook':
      shareUrl = 'https://www.facebook.com/dialog/feed';
      vals = {
        app_id: share.fb_app_id,
        name: share.title,
        link: share.url,
        picture: share.picture,
        caption: share.source,
        description: share.description,
        redirect_uri: share.url
      };
      break;
    case 'twitter':
      shareUrl = 'https://twitter.com/intent/tweet';
      vals = {
        text: share.twitter
      };
      break;
    case 'linkedin':
      shareUrl = 'https://www.linkedin.com/shareArticle';
      vals = {
        url: share.url,
        mini: 'true',
        title: share.title,
        summary: share.description,
        source: share.source
      };
      break;
    default:
    }
    shareUrl += '?' + this.parameterize(vals);
    if (shareUrl) {
      window.open(shareUrl, '_share_on_'+network, this.parameterize(this.popup, ','));
    }
  }

  parameterize(obj, sep = '&') {
    var str = '';
    for (var key in obj) {
      if (str != '') {
        str += sep;
      }
      str += key + '=' + encodeURIComponent(obj[key]);
    }
    return str;
  }

  bindEvent(el, eventName, eventHandler) {
    if (el.addEventListener){
      el.addEventListener(eventName, eventHandler, false);
    }
    else if (el.attachEvent){
      el.attachEvent('on'+eventName, eventHandler);
    }
  }
}
