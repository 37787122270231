// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import 'browsernizr/test/svg';
import 'browsernizr/test/touchevents';
import 'browsernizr/test/css/vhunit';
import 'browsernizr/test/css/flexbox';
import 'browsernizr/test/css/transitions';
import browsernizr from 'browsernizr';
import 'waypoints/lib/noframework.waypoints';
import imagesLoaded from 'imagesloaded';
import Hero from '../_modules/hero/hero';
import Header from '../_modules/header/header';
import Form from '../_modules/form/form';
import Sticky from '../_modules/sticky/sticky';
import Modal from '../_modules/modal/modal';
// import Countdown from '../_modules/countdown/countdown';
import Tooltip from 'tooltip-module';
import Buttons from '../_modules/button/button';
import 'validatinator';
import 'classlist-polyfill'; //ie9
import Rellax from 'rellax';

document.addEventListener('DOMContentLoaded', function() {
  let header = new Header();
  let hero = new Hero();
  let contest = new Form('contest_form');
  let contact = new Form('contact_form');
  let generalcontact1 = new Form('general_contact_form1');
  let generalcontact2 = new Form('general_contact_form2');
  let sticky = new Sticky();
  window.modal = new Modal();
  new Rellax('.parallax');
  // new Countdown(); // top countdown element
  new Buttons(); // social sharesies
  window.interruptorTimeout;

  function bindEventToForm(formObjs, eventName, eventHandler) {
    if(!Array.isArray(formObjs)) formObjs = [formObjs];
    for (let i = 0; i < formObjs.length; i++) {
      if (formObjs[i].element != undefined) {
        if (formObjs[i].element.addEventListener){
          formObjs[i].element.addEventListener(eventName, function(e){
            eventHandler(e, formObjs[i]);
          }, false);
        }
        else if (formObjs[i].element.attachEvent){
          formObjs[i].element.attachEvent('on'+eventName, function(e){
            eventHandler(e, formObjs[i]);
          });
        }
      }
    }
  }

  // initialize tooltips
  let tooltipContainers = document.querySelectorAll('td.img-tooltip');
  for (let i = 0; i < tooltipContainers.length; i++) {
    new Tooltip({
      el: tooltipContainers[i],
      activeClass: 'img-tooltip-active',
      triggerClass: 'img-tooltip-btn',
      showEvent: 'mouseover',
      hideEvent: 'mouseout'
    });
  }

  // builds an object of form elements for validation
  // with Validatinator
  let forms = document.getElementsByTagName('form');
  let formObj = {};
  for (let i = 0; i < forms.length; i++) {
    let formName = forms[i].getAttribute('name');
    formObj[formName] = {};
    let fields = forms[i].querySelectorAll('.validate');
    for (let i = 0; i < fields.length; i++) {
      let name = fields[i].getAttribute('name');
      let validation = fields[i].getAttribute('data-validation');
      if (validation) {
        formObj[formName][name] = validation;
      }
    }
  }

  let validatinator = new Validatinator(formObj, {
    'countryCodeMatchStateCode': 'Double check your selection, ensure that your State/Province is correct'
  });

  bindEventToForm(contest, 'submit', function(e, formObj){
  //contest.element.addEventListener('submit', function(e) {
    e.preventDefault(); // reject the form.. we're handling it with AJAX
    formObj.removeErrors(); // remove existing errors, if any
    if (validatinator.fails(formObj.formName)) {
      // add errors if validation fails
      if (typeof ga == 'function')
        ga('send', 'event', 'contest', 'entry', 'validation_failed');
      formObj.addErrors(validatinator.errors[formObj.formName]);
    }
    else {
      // submit with ajax when validation passes
      formObj.ajaxPost(function(xhr) {
        if (xhr.target.readyState === 4) {
          if (xhr.target.status === 200 && xhr.target.responseText == 'OK') {
          // open the thank you modal
            if (typeof ga == 'function')
              ga('send', 'event', 'contest', 'entry', 'success');
            modal.vm.open('#contest-thank-you');
            formObj.element.reset();
          }
          else {
            if (typeof ga == 'function')
              ga('send', 'event', 'contest', 'entry', 'internal_error');
            alert('Error');
            console.log('Error', xhr.target.statusText, xhr.target.responseText);
          }
        }
      });
    }
  });

  bindEventToForm([contact, generalcontact1, generalcontact2], 'submit', function(e, formObj){
    formObj.removeErrors(); // remove existing errors, if any
    if (validatinator.fails(formObj.formName)) {
      // reject the form..
      e.preventDefault();
      // add errors if validation fails
      if (typeof ga == 'function')
        ga('send', 'event', 'contactForm', 'entry', 'validation_failed');
      formObj.addErrors(validatinator.errors[formObj.formName]);
    }
    else {
      // submit normally when validation passes
      if (typeof ga == 'function')
        ga('send', 'event', 'contactForm', 'entry', 'success');
    }
  });

  /*
  Deactivate the hero's slider autoplay once scrolled past it
  */
  imagesLoaded( hero.element, function( instance ) {
    if (browsernizr.csstransitions) {
      hero.initFlickity(function() {
        new Waypoint({
          element: hero.element.nextElementSibling,
          handler(direction) {
            if (direction == 'down') {
              hero.flickity.deactivatePlayer();
            }
            else {
              hero.flickity.activatePlayer();
            }
          },
          offset() {
            return header.element.offsetHeight + 1;
          }
        });
      });
    }
  });

  /*
  Sticky element gets unstuck when that element's scrollto
  section is in view.
  */
  if (sticky.scrollto && sticky.element) {
    imagesLoaded( document, function( instance ) {
      setTimeout(function() {
        new Waypoint({
          element: sticky.scrollto,
          handler(direction) {
            if (direction == 'down') {
              sticky.element.classList.add('unstick');
            }
            else {
              sticky.element.classList.remove('unstick');
            }
          },
          offset: '100%'
        });
      }, 1000);
    });
  }

  /*
  Any links that scroll to the pre-order section should instead
  open the pre-order-modal *if* that section is already in view...
  */
  let offerSection = ['special-offer', 'special-offer-promo'];
  for (let i = 0; i < offerSection.length; i++) {
    let section = offerSection[i];
    if (document.getElementById(section)) {
      let preOrderScrollToLink = document.querySelectorAll('a[href="#'+section+'"][data-scroll]');
      let openPreOrderModal = function(e) {
        //console.log('#'+section+'-modal');
        modal.vm.open('#general-contact', e);
      };
      new Waypoint({
        element: document.getElementById(section),
        handler(direction) {
          for (let i = 0; i < preOrderScrollToLink.length; i++) {
            if (direction == 'down') {
              preOrderScrollToLink[i].addEventListener('click', openPreOrderModal, false);
            }
            else {
              preOrderScrollToLink[i].removeEventListener('click', openPreOrderModal);
            }
          }
        },
        offset: '100%'
      });
    }
  };

  // Add support for classlist on svg elments to address bug in IE11
  if (!('classList' in document.createElementNS('http://www.w3.org/2000/svg','g'))) {
    var descr = Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'classList');
    Object.defineProperty(SVGElement.prototype, 'classList', descr);
  }

  /*
  Courtsey of http://clubmate.fi/setting-and-reading-cookies-with-javascript/
  */
  function createCookie(name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = '; expires=' + date.toGMTString();
    } else var expires = '';
    document.cookie = escape(name) + '=' + escape(value) + expires + '; path=/';
  }
  function readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1,c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length,c.length);
      }
    }
    return null;
  }

  // Show contact modal if they stay on the page for 2 minutes
  if(readCookie('seenInterrupter') == 1) {
    // already seen it
  }
  else {
    window.interruptorTimeout = setTimeout(function() {
      modal.vm.open('#general-contact');
      createCookie('seenInterrupter', 1, 14);
    }, 60000); // 60000 = 1 minute
  }
});
