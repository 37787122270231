'use strict';
import 'validatinator';
import smoothScroll from 'smooth-scroll';

export default class Form {
  constructor(name) {
    this.formName = name;
    this.errorMsgFields = [];
    this.errorFields = [];
    this.element = document.getElementsByName(this.formName)[0];
  }
  removeErrors() {
    this.element.classList.remove('has-errors');
    for (let i = 0; i < this.errorMsgFields.length; i++) {
      this.errorMsgFields[i].remove();
    }
    for (let i = 0; i < this.errorFields.length; i++) {
      this.errorFields[i].classList.remove('error');
    }
    this.errorMsgFields = [];
    this.errorFields = [];
  }
  addErrors(errors) {
    this.element.classList.add('has-errors');
    for (let fieldName in errors){
      if (errors.hasOwnProperty(fieldName)) {
        let field = document.getElementById(`field-${this.formName}-${fieldName}`);
        this.addError(field, errors[fieldName]);
      }
    }
    if (this.errorFields.length > 0) {
      smoothScroll.animateScroll( this.errorFields[0].parentNode );
    }
  }
  addError(field, messages) {
    field.classList.add('error');
    let errorObject = document.createElement('p');
    errorObject.classList.add('error-message');
    for (let msg in messages){
      errorObject.innerHTML += messages[msg] + ' ';
    }
    this.errorFields.push(field);
    this.errorMsgFields.push(errorObject);
    field.parents('.form-group')[0].appendChild(errorObject);
  }

  /**
  * Takes a form node and sends it over AJAX.
  * @param {HTMLFormElement} form - Form node to send
  * @param {function} callback - Function to handle onload.
  *                              this variable will be bound correctly.
  */
  ajaxPost(callback) {
    let form = this.element;
    var url = form.action, xhr = new XMLHttpRequest();

    //This is a bit tricky, [].fn.call(form.elements, ...) allows us to call .fn
    //on the form's elements, even though it's not an array. Effectively
    //Filtering all of the fields on the form
    var params = [].filter.call(form.elements, function(el) {
      //Allow only elements that don't have the 'checked' property
      //Or those who have it, and it's checked for them.
      return el.type !== 'checkbox' || el.type !== 'radio' || el.checked;
      //Practically, filter out checkboxes/radios which aren't checekd.
    })
    .filter((el) => !!el.name) //Nameless elements die.
    .map((el) => encodeURIComponent(el.name) + '=' + encodeURIComponent(el.value))
    .join('&'); // Then join all the strings by &

    //Send the proper header information along with the request
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    //.bind ensures that this inside of the function is the XHR object.
    xhr.onload = callback.bind(xhr);

    //All preperations are clear, send the request!
    xhr.send(params);
  }
}

Element.prototype.parents = function(selector) {
  var elements = [];
  var elem = this;
  var ishaveselector = selector !== undefined;

  while ((elem = elem.parentElement) !== null) {
    if (elem.nodeType !== Node.ELEMENT_NODE) {
      continue;
    }

    if (!ishaveselector || elem.matches(selector)) {
      elements.push(elem);
    }
  }

  return elements;
};

Validatinator.prototype.validations.countryCodeMatchStateCode = function(fieldValue) {

  var selectedPlace = this.utils.getFieldsValue(this.parent.currentForm, 'state_code');
  if (selectedPlace == '')
    return true;

  let validPlaces = [];
  switch (fieldValue) {
  case 'CA':
    for (var i = 0; i < document.querySelectorAll('.in-canada').length; i++) {
      validPlaces.push(document.querySelectorAll('.in-canada')[i].value);
    }
    break;
  case 'US':
    for (var i = 0; i < document.querySelectorAll('.in-usa').length; i++) {
      validPlaces.push(document.querySelectorAll('.in-usa')[i].value);
    }
    break;
  default:
    return true;
  }
  return validPlaces.indexOf(selectedPlace) >= 0;
};
