'use strict';

import ScrollSpy from 'scrollspy-js';
import smoothScroll from 'smooth-scroll';

export default class Header {
  constructor() {
    let _this = this;
    this.element = document.getElementById('banner');

    // initialize ScrollSpy,
    // adds .is-inview to nav links when visible in window
    new ScrollSpy(document.body, {
      nav: '#banner nav a',
      className: 'is-inview'
    });

    // transition between in-page anchor links
    smoothScroll.init({
      selector: '[data-scroll]',
      selectorHeader: '#banner',
      speed: 800,
      // make sure the .is-inview class is applied to these elements,
      // sometimes they're impossible to actually scroll to
      // (for example, sections at the bottom of the page)
      callback: function(anchor, toggle) {
        if (toggle) {
          setTimeout(function() {
            _this.element.querySelector('.is-inview').classList.remove('is-inview');
            toggle.classList.add('is-inview');
          }, 100);
        }
      }
    });

    // Rewrite this function from ScrollSpy to include an offset
    ScrollSpy.prototype.isInView = function (el) {
      var scrollOffset = _this.element.offsetHeight * -1,
        winH = this.winH,
        scrollTop = this.doc.documentElement.scrollTop || this.doc.body.scrollTop,
        scrollBottom = scrollTop + winH,
        rect = el.getBoundingClientRect(),
        elTop = rect.top + scrollTop + scrollOffset,
        elBottom = elTop + el.offsetHeight;
      return (elTop < scrollBottom) && (elBottom > scrollTop);
    };
  }
}
