'use strict';

import VanillaModal from 'vanilla-modal';

export default class Modal {
  constructor() {
    let _this = this;
    let queryString = this.queryString();
    var modalTimeout;

    this.vm = new VanillaModal({
      onOpen(e) {
        clearTimeout(modalTimeout);
        if (typeof e !== 'undefined') {
          name = e.target.hash.substring(1);
          if (name == 'general-contact') {
            window.interruptorTimeout = '';
          }
          if (typeof ga == 'function')
            ga('send', 'event', 'modal', 'opened', name);
          if (typeof fbq == 'function')
            fbq('trackCustom', 'ModalOpened', {'name': name});
        }
        else {
          if (typeof ga == 'function')
            ga('send', 'event', 'modal', 'opened', queryString.show);
          if (typeof fbq == 'function')
            fbq('trackCustom', 'ModalOpened', {'name': queryString.show});
        }
      }
    });

    if (queryString.show) {
      _this.vm.open(`#${queryString.show}`);
    }
    else if (document.getElementById('special-offer-promo-modal')) {
      // show "special offer" modal after 30 seconds
      modalTimeout = setTimeout(function() {
        _this.vm.open('#special-offer-promo-modal');
      }, 30000); // 30 seconds
    }
    else if (queryString.returning) {
      // show "returning" modal via the ?returning=1 url query string
      modalTimeout = setTimeout(function() {
        _this.vm.open('#returning');
      }, 5000); // 5 seconds
    }

    // adding a method for triggering a modal to open
    // from within a modal...
    let delayed = document.querySelectorAll('[data-modal-open-delayed]');
    for (var i = 0; i < delayed.length; i++) {
      delayed[i].addEventListener('click', function(e) {
        setTimeout(function() {
          _this.vm.open(e.target.getAttribute('href'), e);
        }, 500);
      }, false);
    }
  }

  queryString() {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    var queryString = {};
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split('=');
      // If first entry with this name
      if (typeof queryString[pair[0]] === 'undefined') {
        queryString[pair[0]] = decodeURIComponent(pair[1]);
        // If second entry with this name
      }
      else if (typeof queryString[pair[0]] === 'string') {
        var arr = [ queryString[pair[0]],decodeURIComponent(pair[1]) ];
        queryString[pair[0]] = arr;
        // If third or later entry with this name
      }
      else {
        queryString[pair[0]].push(decodeURIComponent(pair[1]));
      }
    }
    return queryString;
  }
}
